.table-dark input[type="text"],
.table-dark input[type="number"],
.table-dark input[type="date"] {
  background-color: #333;
  border: 1px solid #555;
  color: #ccc;
  padding: 5px 10px;
  border-radius: 4px;
  width: 100%;
}

/* Style for checkbox in dark table */
.table-dark input[type="checkbox"] {
  background-color: #333;
  border: 1px solid #555;
  cursor: pointer;
}

/* Style for delete button in dark table */
.delete-button {
  background-color: #dc3545;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

.table-dark button:hover {
  background-color: #c82333;
}
