.DateRangePicker_picker {
  z-index: 100 !important;
  transform: scale(0.85);
  transform-origin: top center;
}

.DateInput_input {
  font-size: 0.9rem !important;
  cursor: pointer;
}

.DateInput {
  width: 100px;
}

.DateInput_input__focused,
.DateInput_input {
  padding: 5px 10px !important;
}

.CalendarDay__default {
  padding: 4px 0 !important;
}

.DateRangePickerInput_arrow {
  display: none !important;
}


