.grouped-trades {
  max-height: 400px;
  overflow-y: auto;
}

.ag-theme-alpine .ag-row .ag-cell {
  border-right: 1px solid white;
}

/* To make sure the last column in the data rows also has a border */
.ag-theme-alpine .ag-row .ag-cell-last {
  border-right: 1px solid white;
}

.ag-theme-alpine .ag-header-cell:hover {
  background-color: #181d29 !important;
}

.ag-theme-alpine .ag-header-cell.ag-header-cell-sortable:hover {
  background-color: #181d29 !important;
}

.ag-theme-alpine .ag-root-wrapper-body {
  background-color: #181d29;
}

/* Set the text color of cells to white */
.ag-theme-alpine .ag-cell {
  color: white;
  background-color: #181d29;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Set header background to black and text to white */
.ag-theme-alpine .ag-header-cell, .ag-theme-alpine .ag-header-group-cell {
  background-color: #181d29;
  color: white;
}

.win-status {
  background-color: lightgreen;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  display: inline-block;
}

.loss-status {
  background-color: salmon;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  display: inline-block;
}

.table-container {
  margin-top: 20px;
  max-width: 100%;
  overflow-x: auto;
  max-height: 600px;

  th {
    font-family: 'Roboto', sans-serif !important;
  }
}

.trades-container {
  max-height: 1000px;

  .bg-success {
    background-color: #28a745;
    color: white;
  }

  .bg-danger {
    background-color: #dc3545;
    color: white;
  }
}

.table-image {
  height: 20px;
  width: 20px;
}

.recent-journal-entries {
  max-height: 100vh !important;
}

.apx-legend-position-left {
  padding-top: 20px !important;
}

.table {
  text-align: center;
}

.table th,
.table td {
  vertical-align: middle;
}

.table-image {
  cursor: pointer;
  max-width: 50px;
}

.actions {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.table td {
  padding: 0.5rem;
}

.table-image {
  cursor: pointer;
  width: 100%;
  max-height: 50px;
  object-fit: cover;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.actions button {
  padding: 5px 10px;
  font-size: 1rem;
}

