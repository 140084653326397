.custom-spinner {
  width: 70px;
  height: 70px;
  border: 0.25rem solid rgba(2, 0, 0, 0.3);
  border-top: 0.25rem solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
