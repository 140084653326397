@import 'bootstrap/dist/css/bootstrap.min.css';

.pagination {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.pagination button {
  margin: 0 5px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #e0e0e0;
  color: #000;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 16px;
}

.pagination button:hover {
  background-color: #d0d0d0;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination .active {
  background-color: #4CAF50; /* You can choose a color that suits your design */
  color: #fff;
}
