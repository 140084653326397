@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

.login-container {
  display: flex;
  align-items: stretch;
  height: 100vh;

  .image-container {
    flex: 3;
    background-color: #eee;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .form-container {
    flex: 1;
    padding: 2rem;
    background: #1c1c1c;

    .box {
      padding: 2rem;
      width: 450px;
      background: #1c1c1c;
      border-radius: 8px;
      overflow: hidden;
    }

    .form {
      background-color: #28292d;
      z-index: 10;
      padding: 20px 30px;
      display: flex;
      flex-direction: column;
      inset: 2px;
      border-radius: 8px;

      button {
        width: 100%;
        margin-bottom: 20px;
      }

      h2 {
        color: white;
        font-weight: 500;
        align-items: center;
        letter-spacing: 0.1em;
      }

      .inputBox {
        position: relative;
        width: 300px;
        margin-top: 35px;

        input {
          position: relative;
          width: 100%;
          padding: 20px 10px 10px;
          background: transparent;
          border: none;
          outline: none;
          color: white;
          font-size: 1em;
          letter-spacing: 0.05em;
          z-index: 10;
        }

        span {
          position: absolute;
          left: 0;
          padding: 20px 0px 10px;
          font-size: 1em;
          color: #8f8f8f;
          pointer-events: none;
          letter-spacing: 0.05em;
          transition: 0.5s;
        }

        input:valid ~ span,
        input:focus ~ span {
          color: white;
          transform: translateX(0px) translateY(-34px);
          font-size: 0.75em;
        }

        i {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          background: linear-gradient(50deg, transparent, red, purple);
          border-radius: 4px;
          transition: 0.5s;
          pointer-events: none;
          z-index: 9;
        }

        input:valid ~ i,
        input:focus ~ i {
          height: 44px;
        }
      }

      .links {
        display: flex;
        justify-content: space-between;

        a {
          margin: 10px 0;
          font-size: 0.75em;
          color: white;
          text-decoration: none;
          cursor: pointer;
        }

        a:hover,
        a:nth-child(2) {
          color: #8f8f8f;
        }
      }

      input[type="submit"] {
        border-radius: 4px;
        font-size: 1rem;
        padding: .5rem 3rem;
        color: white;
        box-shadow: 0 0 6px 0 purple;
        border: solid 1px transparent;
        background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, red, purple);
        background-origin: border-box;
        background-clip: content-box, border-box;
        transition: all .9s ease;

        &:hover {
          box-shadow: 0 7px 50px 10px #000000aa;
          transform: scale(1.015);
          filter: brightness(1.3);

          &::before {
            filter: brightness(2);
            top: -100%;
            left: 200%;
          }
        }

        &:active {
          opacity: 0.8;
        }
      }
    }
  }
}



@media(max-width: 600px) {

  .image-container {
    display: none;
  }

  .box {
    margin-top: 150px;
    width: 100% !important;
    padding: 0 !important;
  }
}

