.journal-button {
  border-radius: 8px;
  font-size: 1rem;
  padding: .5rem 1rem;
  color: white;
  outline: none;
  box-shadow: 0 0 6px 0 purple;
  border: solid 1px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, red, purple);
  background-origin: border-box;
  background-clip: content-box, border-box;
  transition: all .9s ease;
}
