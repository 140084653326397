.nft-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 0.6rem;
}

.nft {
  user-select: none;
  min-width: 300px;
  max-width: 300px;
  margin: 1rem auto;
  border: 1px solid #ffffff22;
  background-color: rgb(38, 45, 71, 0.3);
  background: linear-gradient(0deg, rgba(40,44,52,1) 0%, rgba(17,0,32,.5) 100%);
  box-shadow: 0 7px 20px 5px #00000088;
  border-radius: .7rem;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  overflow: hidden;
  transition: .5s all;

  hr {
    width: 100%;
    border: none;
    border-bottom: 1px solid #88888855;
    margin-top: 0;
  }

  ins {
    text-decoration: none;
  }

  h2 {
    color: white;
  }

  .main {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;

    .tokenImage {
      border-radius: .5rem;
      max-width: 100%;
      height: 250px;
      object-fit: cover;
      cursor: pointer;
    }

    .lot_size {
      color: #a89ec9;
    }

    .description {
      margin: .5rem 0;
      color: #a89ec9;

      &.buy {
        color: green;
        font-weight: bold;
        background-color: rgba(0, 255, 0, 0.1);
        padding: 0.2rem 0.5rem;
        border-radius: 0.5rem;
      }

      &.sell {
        color: red;
        font-weight: bold;
        background-color: rgba(255, 0, 0, 0.1);
        padding: 0.2rem 0.5rem;
        border-radius: 0.5rem;
      }
    }

    .tokenInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .price, .duration {
        display: flex;
        align-items: center;

        ins {
          margin-left: -.3rem;
          margin-right: .5rem;
        }
      }

      .price {
        color: rgb(14, 203, 129);
        font-weight: 700;
      }

      .negative {
        color: rgb(246, 70, 93);
      }

      .duration {
        color: #a89ec9;
        margin-right: .2rem;

        ins {
          margin: .5rem;
          margin-bottom: .4rem;
        }
      }
    }

    .creator {
      display: flex;
      align-items: center;
      margin-top: .2rem;
      margin-bottom: -.3rem;

      ins {
        color: #a89ec9;
        text-decoration: none;
      }

      .wrapper {
        display: flex;
        align-items: center;
        border: 1px solid #ffffff22;
        padding: .3rem;
        margin: 0;
        margin-right: .5rem;
        border-radius: 100%;
        box-shadow: inset 0 0 0 4px #000000aa;

        img {
          border-radius: 100%;
          border: 1px solid #ffffff22;
          width: 2rem;
          height: 2rem;
          object-fit: cover;
          margin: 0;
        }
      }

      .actions {
        display: flex;
        justify-content: space-between;
        width: 100%;

        button, .TradeModal {
          flex: 1;
          margin: 0 0.5rem;
          max-width: calc(50% - 1rem);
        }
      }
    }
  }

  ::before{
    position: fixed;
    content: "";
    box-shadow: 0 0 100px 40px #ffffff08;
    top: -10%;
    left: -100%;
    transform: rotate(-45deg);
    height: 60rem;
    transition: .7s all;
  }
  &:hover{
    border: 1px solid #ffffff44;
    box-shadow: 0 7px 50px 10px #000000aa;
    transform: scale(1.015);
    filter: brightness(1.3);
    ::before{
      filter: brightness(2);
      top: -100%;
      left: 200%;
    }
  }
}

.bg {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h1 {
    font-size: 20rem;
    filter: opacity(0.5);
  }
}
