.stats-card {
  border-radius: 12px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
}

.card-content {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    font-size: 30px !important;
  }

  .text-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .card-text {
      font-family: 'Monda';
      font-weight: 800 !important;
      color: black;
    }
  }
}

