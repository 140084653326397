@import './bootstrap/imports';
@import './utils';
@import './components/button';
@import './components/pagination';
@import './components/calendar';
@import './components/sidebar';
@import './components/stats-card';
@import 'react-datetime-picker/dist/DateTimePicker.css';
@import 'react-dates/lib/css/_datepicker.css';
@import 'react-calendar/dist/Calendar.css';
@import 'react-clock/dist/Clock.css';
@import './components/customDatePickerStyles';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&family=Space+Grotesk:wght@400;500&display=swap');

html * {
  font-family: 'Roboto', sans-serif;
}

body, html, #root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}


.default-cursor {
  cursor: default;
}

.main-content {
  flex: 1;
  min-height: 100vh;
}

.mt-6 {
  margin-top: 6rem;
}

.apexcharts-title-text {
  font-family: 'Roboto', sans-serif !important;
}


h1, h2, h3, h4, text {
  color: white !important;
  font-family: 'Roboto', sans-serif;
}

.insight-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.insight-card {
  flex: 0 0 calc(25% - 10px);
  margin-right: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  height: 150px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  position: relative;
}

.insight-header {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 10px;
}

.insight-footer {
  margin-top: 10px;
  text-align: right;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.modal-title {
  color: black !important;
}

.chart-container {
  margin-top: 0.4rem;
  margin-bottom: 20px;

  .row {
    max-width: 100% !important;
  }
}


@media(max-width: 600px) {

  .dropdown-menu.show {
    left: 0 !important;
  }

    .DateRangePicker_picker {
    left: -90px !important;
    width: 320px !important;
    height: 600px !important;
    max-width: 320px !important;
  }

  .DayPicker {
    width: 320px !important;
    max-width: 320px !important;
  }

  .DayPicker > div > div {
    width: 320px !important;
  }

  .DayPicker_weekHeader_1:nth-child(2) {
    left: 0 !important;
    top: 310px
  }

  .DayPicker_transitionContainer {
    height: 600px !important;
    max-width: 320px !important;
  }

  .CalendarMonthGrid_month__horizontal {
    display: block !important;
  }
}
