.sidebar {
  width: 80px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: width 0.3s ease;
  z-index: 100000;
  color: white;
  overflow: hidden;
  background-color: #1F2A40;

  &.expanded {
    width: 200px;
  }

  &.expanded .sidebar-item-content {
    justify-content: flex-start;
  }

  &:not(.expanded) .sidebar-item-content {
    justify-content: center;
  }

  .dropdown-label {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    text-align: center;

    span {
      margin-left: 10px;
    }
  }

  .sidebar-dropdown-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    a {
      padding-left: 48px;
    }

    .icon {
      margin-left: 15px !important;
    }
  }

  .dropdown-content {
    span {
      color: lightgray !important;
      padding-left: 20px;
    }
  }
}

.sidebar-item {
  display: flex;
  align-items: center;
  width: 100%;
}

.sidebar-item-content {
  display: flex;
  align-items: center;
}

.sidebar-item-icon {
  width: 40px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.sidebar-item-label {
  margin-left: 8px;
}


.sidebar-nav a.active {
  color: grey;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 10px 10px 10px 14px;

  span {
    padding-left: 12px;
  }
}

.expand-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: right 0.3s ease-in-out;
}

.sidebar-nav {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.sidebar-nav a {
  color: white;
  text-decoration: none;
  width: 100%;
  padding: 10px;
  text-align: center;
}

.sidebar-nav a.active {
  color: grey;
}

.sidebar-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-footer a {
  color: white;
  text-decoration: none;
  width: 100%;
  padding: 10px;
  text-align: center;
}
