.trade-calendar {
  width: 100%;
  margin: auto;
}

.react-calendar {
  width: 100%;
  min-width: 100%;
  margin: auto;
}

.event-title {
  font-weight: bold;
}

.event-details {
  font-size: 0.8em;
}

.fc-col-header-cell-cushion {
  color: white;
  text-decoration: none;
}

.fc-daygrid-day-top {
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    color: white;
    text-decoration: none;
  }
}

.fc-daygrid-day-events {
  cursor: pointer;
}

.trade-modal-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.trade-modal-body > div {
  flex: 1 0 calc(20% - 16px);
  margin: 20px;
}

.fc-day-today {
  background-color: transparent !important;
}

.calendar-dropdown {
  padding: 10px;
  background: white;

  label {
    margin-right: 10px;
  }

  select {
    padding: 2px
  }
}


@media(max-width: 600px) {

  .fc-header-toolbar {
    flex-direction: column;
  }

  .fc-toolbar-chunk {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .fc-toolbar-title {
    width: 100%;
    text-align: center;
    margin: 0.5em 0;
  }


  .fc-header-toolbar > .fc-toolbar-chunk:nth-child(2) {
    order: 1;
  }

  .fc-header-toolbar > .fc-toolbar-chunk:nth-child(1) {
    order: 2;
  }

  .fc-header-toolbar > .fc-toolbar-chunk:nth-child(3) {
    order: 3;
  }

  .fc-toolbar-chunk:not(:last-child) {
    margin-bottom: 0.5em;
  }

  .fc-view-harness {
    height: 1100px !important;
  }

  .fc-event-main {
    height: 140px;
  }

  .event-title, .event-details div {
    white-space: normal;
    word-wrap: break-word;
  }
}
